<template>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Overview</h4>
      <div :class="`alert alert-${data.balance > 1000 ? 'info' : 'danger'}`">
        <i class="lni-information alert-icon"></i>
        Your balance is KES {{ $number.format(data.balance) }}. <router-link :to="{ name: 'app.top-up' }">Top Up</router-link>
      </div>
      <div class="row mt-4 app-services">
        <div class="col-md-12">
          <h5>Services</h5>
        </div>
        <div class="col-md-3">
          <div class="card service">
            <router-link :to="{ name: 'app.bulk-sms.analytics' }">
              <img src="/bulk-sms.png" alt="bulk-sms">
              <div class="name">Bulk SMS</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      data: {}
    }
  },

  methods: {
    fetch() {
      this.$loader.start('main-content')
      this.$axios.get(`/api/v1/apps/${this.$route.params.id}/overview`).then(response => {
        this.data = response.data
        this.$loader.stop()
      })
    }
  },

  computed: {
    current_app() {
      return this.$route.params.id
    }
  },

  watch: {
    current_app() {
      this.fetch()
    }
  }
}
</script>